import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Service from "../../services/httpService";
import CCService from "../../services/httpServiceWithoutSuperAdmin";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";

export default function SetPassword() {
  const errRef = useRef();
  const initialValues = { password: "", confirmPwd: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [errMsg, setErrMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const { email, token } = useParams();
  const services = new CCService();
  console.log(email);
  console.log(token);
  console.log(loader);
  console.log(isSubmit);
  console.log(redirect);
  const navigate = useNavigate();

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmit(true);

    console.log("1Email is :", email);
    console.log("1Token is :", token);
    console.log("1Password is :", formValues.password.toString());
    console.log(formValues.password);
    try {
      const response = services
        .post("/setPassword", {
          email: email.substring(1).toString(),
          token: token.substring(1).toString(),
          password: formValues.password.toString(),
        })
        .then(res => {
          if (
            res.status === 200 &&
            res.data.message === "Password successfully saved"
          )
            navigate("/");
          setErrMsg(<p>Password successfully saved.</p>);
          setRedirect(true);
        });
      console.log(response.data.message);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
      console.log(err.response);
      setErrMsg(err.response.data.message);

      console.log(errMsg);
    }
  };

  return (
    <div className="login-Container">
      <div className="login-Resumes_Image">
        <StandardLeftSideOfRD/>
      </div>

      <div className="login-Main-content">
        {/* logo here */}
        <div className="fprgotPasswordCenter">
          <p className="login-name">Reset your Password</p>

          <div className="login-l-form">
            <form action="" className="login-form">
              <div className="outlined-basic">
                <p
                  className="forgotpasswordtext"
                  style={{ marginBottom: "2rem" }}
                >
                  To revive your account, set a new password
                </p>

                <FormControl
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  variant="outlined"
                  name="password"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    onChange={handleChange}
                    value={formValues.password}
                    id="outlined-adornment-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <br />
                <FormControl
                  sx={{ width: 400, mb: 3 }}
                  InputProps={{ sx: { height: 50.4 } }}
                  variant="outlined"
                  name="password"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    onChange={handleChange}
                    value={formValues.confirmPwd}
                    id="outlined-adornment-password"
                    name="confirmPwd"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                </FormControl>
                <button
                  className="Login-button"
                  style={{
                    textAlign: "center",
                    marginBottom: "1.5rem",
                    width: "400px",
                  }}
                  onClick={e => handleSubmit(e)}
                >
                  Submit
                </button>
                <p
                  className="alert-errMsg-button"
                  ref={errRef}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
