import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Box } from "@mui/material";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import Alert from "../../components/Modal/Alert";
// import { useTimer } from "../ResendButton/ResendButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-toastify/dist/ReactToastify.css";
import "../Login/Login.css";
import StandardLeftSideOfRD from "../StandardLeftSideOfRD/StandardLeftSideOfRD";

const Login = () => {
  const navigate = useNavigate();
  // const services = new Service(setUnderMaintenanceCalled);
  const Mobile = useMediaQuery("(max-width:800px)");
  const [token, setToken] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  // const [showPassword, setShowPassword] = React.useState(false);
  // const handleClickShowPassword = () => setShowPassword(show => !show);
  // const firebaseConfig = {
  //   apiKey: "AIzaSyBbrNdmcQY1vAhXy3Zg0gHUJ3zWJCuAbRI",
  //   authDomain: "careercarvelogin.firebaseapp.com",
  //   projectId: "careercarvelogin",
  //   storageBucket: "careercarvelogin.appspot.com",
  //   messagingSenderId: "151843323088",
  //   appId: "1:151843323088:web:000a09c7a3d8067ae072d5",
  // };
  // const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);
  const [changeTemplate, setChangeTemplate] = useState(false);
  // const { formInputs, setFormInputs } = useContext(AppContext);

  useEffect(() => {
    const handleEffect = () => {
      const user_type = Cookies.get("user_type");
      let tokenHere = Cookies.get("token");
      setToken(tokenHere);

      const isValidToken = (value) => value && value !== "null" && value !== "undefined";

      if (isValidToken(tokenHere)) {
        if (user_type === "CareerCarve College Admin") {
          navigate("/CollegeInfoPage");
        }
      }
    };

    handleEffect();
  }, [token]);

  // const fetch_userDetail = async () => {
  //   try {
  //     const preUser = await services.get("/user/userDetail");
  //     console.log("preUser", preUser);
  //     const temp_formInputs = { ...formInputs };
  //     temp_formInputs.first_name = preUser?.userDetail.first_name;
  //     temp_formInputs.user_id = preUser?.userDetail.user_id;
  //     if (
  //       ["undefined", "null", undefined, null].includes(
  //         temp_formInputs.first_name
  //       )
  //     ) {
  //       temp_formInputs.first_name = "";
  //     }

  //     temp_formInputs.last_name = preUser?.userDetail.last_name;
  //     if (
  //       ["undefined", "null", undefined, null].includes(
  //         temp_formInputs.last_name
  //       )
  //     ) {
  //       temp_formInputs.last_name = "";
  //     }
  //     temp_formInputs.userType = preUser?.user_type;
  //     console.log("handleEffect ~ temp_formInputs:", temp_formInputs);
  //     setFormInputs(temp_formInputs);
  //     return temp_formInputs;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // handle sign in click
  // async function handleSignInClick() {
  //   setIsLoading(true);
  //   try {
  //     const result = await signInWithPopup(
  //       auth,
  //       new OAuthProvider("apple.com")
  //     );
  //     // The signed-in user info.
  //     const user = result?.user;
  //     console.log("apple user deatils", user);
  //     // Apple credential
  //     const credential = OAuthProvider?.credentialFromResult(result);
  //     const accessToken = credential.accessToken;
  //     console.log("apple accessToken deatils", accessToken);
  //     console.log("user name", result.user.displayName);
  //     console.log("user email", result.user.email);

  //     const idToken = credential.idToken;
  //     console.log("apple idToken deatils", idToken);
  //     if (idToken !== null && idToken !== undefined) {
  //       if (result.user.email !== null && result.user.email !== undefined) {
  //         const res = await services.post(`/appleAuth`, {
  //           id_token: idToken,
  //           signup_platform: "CareerCarve Web App",
  //         });
  //         // if (res.status === 200) {
  //         //   console.log("ut", res.data.user_type);
  //         //   console.log("login with apple successful");
  //         //   navigate("/UpcomingBooking", {
  //         //     state: { user_type: res.data.user_type },
  //         //   });
  //         //   Cookies.set("token", res.headers.jwt, { expires: 182.5 });
  //         // }

  //         if (res.status === 200) {
  //           Cookies.set("token", res.headers.jwt, {
  //             expires: 182.5,
  //             sameSite: "none",
  //           });

  //           //TODO: CHANGES HERE

  //           Cookies.set("user_type", res.data.user_type, { expires: 182.5 });
  //           console.log("user type right now at login", res.data.user_type);
  //           if (res.data.user_type === "mentor") {
  //             navigate("/UpcomingBooking", {
  //               state: {
  //                 formValues: formValues,
  //                 user_type: res.data.user_type,
  //               },
  //             });
  //           } else if (res.data.user_type === "student b2c") {
  //             navigate("/StudentActivity", {
  //               state: {
  //                 formValues: formValues,
  //                 user_type: res.data.user_type,
  //               },
  //             });
  //           } else {
  //             navigate("/StudentActivity", {
  //               state: {
  //                 formValues: formValues,
  //                 user_type: res.data.user_type,
  //               },
  //             });
  //           }

  //           // alert.box(<h1>DASHBOARD</h1>);
  //           console.log("Dashboard");
  //           setErrMsg("Login successful.");
  //           const cookies = res.headers.jwt;
  //           console.log({ cookies });
  //         }
  //         console.log(res);
  //       } else {
  //         if (result.user.email === null || result.user.email === undefined) {
  //           toast.error("Email ID is required ");
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     if (
  //       err?.response.status === 400 &&
  //       err?.response.data.message === "Unverified account"
  //     ) {
  //       setErrMsg("This email is Unverified.");
  //     } else if (
  //       err?.response.status === 400 &&
  //       err?.response.data.message ===
  //         "Seems like your account dosn't exist with us. Please Signup using CareerCarve App."
  //     ) {
  //       setChangeTemplate(true);
  //       setErrMsg(
  //         "Seems like your account doesn't exist with us. Please Signup using CareerCarve App."
  //       );
  //     } else if (
  //       err?.response.status === 400 &&
  //       err?.response.data.message === "Alternate email already existing."
  //     ) {
  //       setErrMsg("Alternate email already existing.");
  //     } else if (
  //       err?.response.status === 400 &&
  //       err?.response.data.message === "Email already exists as alternate."
  //     ) {
  //       setErrMsg(
  //         "This email already exists as an secondary email. Please login with your primary email."
  //       );
  //     } else if (
  //       err?.response.status === 500 &&
  //       err?.response.data.message === "Alternate email entry doesn't exists"
  //     ) {
  //       setErrMsg("Alternate email entry doesn't exists.");
  //     }
  //     console.log("apple sign in error", err);
  //     const errorCode = err.code;
  //     console.log("apple sign in error", errorCode);

  //     const errorMessage = err.message;
  //     console.log("apple sign in error", errorMessage);

  //     const email = err.customData.email;
  //     console.log("apple sign in email", email);

  //     const credential = OAuthProvider.credentialFromError(err);
  //     console.log("apple sign in credential", credential);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  // const errRef = useRef();
  // const clientId =
  //   "331878265619-n1b1ia44qjlp90amsdg2trohjqt9m51o.apps.googleusercontent.com";

  // const initialValues = { email: "", password: "" };
  // const [formValues, setFormValues] = useState(initialValues);

  // const [formErrors, setFormErrors] = useState({});

  // const [errMsg, setErrMsg] = useState("");
  //for validation

  // const [condition, setCondition] = useState("");
  // const [isSubmit, setIsSubmit] = useState(false);

  // const [showPassword, setShowPassword] = useState(false);
  // const [id_token, setId_token] = useState(null)

  // const handleResend = async e => {
  //   e.preventDefault();
  //   setResendTime(10);
  //   await service
  //     .post(`/resendVerify`, { email: formValues.email })
  //     .then(res => {
  //       if (res.status === 200) {
  //         setErrMsg(
  //           <p>
  //             Link successfully resent to {formValues.email}
  //             <br />
  //             Didn't get the link?
  //             <button
  //               className="signup-button"
  //               type="button"
  //               onClick={e => handleResend(e)}
  //             >
  //               Resend
  //             </button>
  //           </p>
  //         );
  //       }
  //     });
  // };

  // const handleFormSubmit = e => {
  //   console.log("login");
  //   const signUpButtonText = "Sign up now"; // Replace this with your desired dynamic text
  //   const endpoint = "/login"; // Replace this with your desired dynamic endpoint
  //   handleSubmit(
  //     e,
  //     formValues,
  //     setFormErrors,
  //     setIsSubmit,
  //     navigate,
  //     setErrMsg,
  //     services,
  //     signUpButtonText,
  //     endpoint,
  //     fetch_userDetail
  //   );
  // };

  // const handleSubmit = async e => {
  //   e.preventDefault();
  //   const formError = validate(formValues);
  //   setFormErrors(formError);
  //   if (Object.keys(formError).length === 0) {
  //     try {
  //       setIsSubmit(true);
  //       const res = await service.post(`/login`, {
  //         email: formValues.email,
  //         password: formValues.password,
  //       });
  //       console.log(res);
  //       // setUserType(res.data.user_type);
  //       console.log(res.data.message);
  //       if (res.status === 200) {
  //         Cookies.set("token", res.headers.jwt, { expires: 182.5 });
  //         Cookies.set("user_type", res.data.user_type, { expires: 182.5 });
  //         console.log("user type right now at login", res.data.user_type);
  //         if (res.data.user_type === "mentor") {
  //           navigate("/UpcomingBooking", {
  //             state: { user_type: res.data.user_type },
  //           });
  //         } else if (res.data.user_type === "student b2c") {
  //           navigate("/StudentActivity", {
  //             state: { user_type: res.data.user_type },
  //           });
  //         } else {
  //           navigate("/StudentActivity", {
  //             state: { user_type: res.data.user_type },
  //           });
  //         }

  //         // alert.box(<h1>DASHBOARD</h1>);
  //         console.log("Dashboard");
  //         setErrMsg("Login successful.");
  //         const cookies = res.headers.jwt;
  //         console.log({ cookies });
  //       }

  //       console.log(res);
  //     } catch (err) {
  //       if (
  //         err?.response.status === 400 &&
  //         err?.response.data.message === "user not registered"
  //       ) {
  //         setErrMsg(
  //           <p>
  //             You have not signed up yet. Please sign up first to create your
  //             account.
  //             <button
  //               className="signup-button"
  //               type="button"
  //               onClick={() => navigate("/GetYourFreeAccount")}
  //             >
  //               Sign up now
  //             </button>
  //           </p>
  //         );
  //         console.log(err);
  //       } else if (
  //         err?.response.status === 401 &&
  //         err?.response.data.message === "unauthorized"
  //       ) {
  //         setErrMsg(<p>Wrong Password</p>);
  //         console.log(err);
  //       } else if (err?.response.data.message === "wrong password") {
  //         setErrMsg(<p>Incorrect password. Please confirm and try again</p>);
  //         console.log(err);
  //       } else if (
  //         err?.response.status === 500 &&
  //         err?.response.data.message === "Unknown Error Occurred"
  //       )
  //         setErrMsg(<p>Unknown Error Occurred</p>);
  //       else if (
  //         err?.response.data.message ===
  //         "Please sign in using Google or Apple ID."
  //       ) {
  //         setErrMsg(
  //           <p>
  //             This email is already signed up using Google/Apple. Please
  //             continue with Google/Apple.
  //           </p>
  //         );
  //       } else if (err?.response.data.message === "verify first") {
  //         setErrMsg(
  //           <p>
  //             Your account is not activated yet. Please check {formValues.email}{" "}
  //             to activate your account.
  //             <br /> Can't find activation link?
  //             <button
  //               className="signup-button"
  //               type="button"
  //               onClick={e => handleResend(e)}
  //             >
  //               Resend
  //             </button>
  //           </p>
  //         );
  //       } else {
  //         // console.log(err?.response.data.message);
  //         setErrMsg(<p>{err.response.data.message}</p>);
  //       }
  //     }
  //   }
  // };

  // const handleChange = async e => {
  //   const { name, value } = e.target;
  //   console.log(e.target.value);
  //   setFormValues({ ...formValues, [name]: value });
  //   setFormErrors({});
  //   setErrMsg("");
  //   console.log("email", formValues.email);
  //   console.log("password", formValues.password);
  // };

  const alertResponse = () => {
    navigate("/SignUp");
  };

  // const handleForgotPassword = e => {
  //   setCondition("ForgotPassword");
  //   navigate("/ForgotPassword", { state: { condition: "ForgotPassword" } });
  // };
  // console.log(condition);

  // const text_alignment = "center";
  // const googleButtonWidth = 465;
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "Login" && window.location.hostname === "collegeadmin.resumes.digital") {
        navigate("/CollegeInfoPage");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const handleNavigation = () => {
      const token = Cookies.get("token");

      if (token) {
        navigate("/CollegeInfoPage");
      }
    };
    handleNavigation();
  }, []);
  return (
    <>
      <div
        className="login-Container"
        style={{
          display: "flex",
        }}
      >
        <ToastContainer position="top-center" />
        {!Mobile && (
          <div className="login-Resumes_Image" style={{ width: "100%" }}>
            <StandardLeftSideOfRD />
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <iframe src={`${process.env.REACT_APP_RESUME_DIGITAL}/Login`} width="100%" height="500px" style={{ border: "none" }} title="Embedded Website" />
        </Box>
        <Alert show={changeTemplate} title="Alert" body="You are not a registered user with us. Please sign using Google/Apple on the CareerCarve App to continue." yesButton="Signup" alertResponse={alertResponse} onHide={() => setChangeTemplate(false)} />
      </div>
    </>
  );
};

export default Login;
