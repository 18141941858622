import React from "react";
import RDCircleImage from "../../assets/images/Group 39162.png";
import "../Login/Login.css";

const StandardLeftSideOfRD = () => {
  return (
    <div className="login-Resumes_Image" style={{ width: "100%", position: "relative" }}>
      {/* Background Image */}
      <img
        className="imageFlexible"
        src={"https://ccicons.s3.us-east-1.amazonaws.com/Resumes.webp"}
        alt="background"
        style={{
          backgroundRepeat: "none",
          float: "left",
          margin: "0",
          width: "100%",
          height: "100%",
          position: "static",
        }}
      />
      {/* Circle Image Centered */}
      <img
        className="imageFlexible"
        src={RDCircleImage}
        alt="circle"
        style={{
          position: "absolute",
          height: "300px",
          width: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

export default StandardLeftSideOfRD;
