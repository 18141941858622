import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
import Cookies from "js-cookie";
import { FaLayerGroup } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAccessRights } from "../../../ReusableModals/Rights";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import { setUser, updateActionRights } from "../../../store/reducers";
import "../SideNavigationBar/SideNavigationBar.css";
const SideNavigationBar = (props) => {
  const navigate = useNavigate();
  const path = window.location.pathname;
  const service = new CCService();

  const { college_id, college_name } = props;
  const { userDetails, accessRights } = useSelector((store) => store.rights);
  const dispatch = useDispatch();

  const [isHovered, setisHovered] = useState(false);

  //OPEN collapsibles

  //LOGOUT
  const handleLogout = (e) => {
    Cookies.remove("token", {
      domain: ".resumes.digital",
      path: "/",
    });

    Cookies.remove("user_type", {
      domain: ".resumes.digital",
      path: "/",
    });
    navigate("/");
  };

  //NAVIGATE functions

  const handleNavigateOfflineSession = (e) => {
    e.preventDefault();
    navigate("/OfflineSession", { state: { college_id: college_id, college_name: college_name } });
  };

  const handleNavigateCollegeAnalytics = (e) => {
    e.preventDefault();
    navigate("/CollegeAnalytics", { state: { college_id: college_id, college_name: college_name } });
  };

  const handleNavigateResumeAnalytics = (e) => {
    e.preventDefault();
    navigate("/ResumeAnalytics", { state: { college_id: college_id, college_name: college_name, fromResumesTemplatePath: true } });
  };

  const handleNavigatePlacementOps = (e) => {
    e.preventDefault();
    navigate(`/Batches`, { state: { college_id: college_id, college_name: college_name } });
  };

  const handleEffect = async () => {
    try {
      const preUser = await service.get("/user/userDetail");

      dispatch(setUser(preUser.userDetail));
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    if (userDetails.user_id === null) {
      handleEffect();
    }
  }, [userDetails.user_id]);

  const getRightsData = async () => {
    const data = await getAccessRights(userDetails.user_id);

    dispatch(updateActionRights(data));
  };

  useEffect(() => {
    if (userDetails.user_id !== null && accessRights.length === 0) {
      getRightsData();
    }
  }, [userDetails.user_id]);

  return (
    <div>
      <div className="area" onMouseOver={() => setisHovered(true)} onMouseLeave={() => setisHovered(false)}>
        <nav className="main-menu">
          <ul>
            <li>
              <a href="/">
                <div
                  style={{
                    padding: "1.7rem",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    alt="logo"
                    src={isHovered ? "ExtendedRDLogo.png" : "RDLogo.png"}
                    style={{
                      // margin: "17px 6px 75px 12px",
                      // marginLeft: "6%",
                      width: "fit-content",
                      height: "30px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </a>
            </li>
          </ul>
          <ul>
            <li class="has-subnav">
              <a href={"/CollegeInfoPage"}>
                <ListItemButton style={{ padding: "0px" }}>
                  <div style={{ display: "flex", width: "100%" }} className={path === "/CollegeInfoPage" ? "sidenav-image-a-dashboard" : ""}>
                    <div
                      style={{
                        padding: "15px",
                        margin: 16,
                        marginRight: 10,
                        paddingRight: "29px",
                        marginTop: 0,
                        marginBottom: 0,
                        borderRight: "none",
                      }}
                      className="avtive-icons"
                    >
                      {/* <div style={{ padding: "15px", margin:16,marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0 ,borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                      <FaLayerGroup />
                    </div>
                    <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                      <span class="nav-text">Dashboard</span>
                    </div>
                  </div>
                </ListItemButton>
              </a>
            </li>
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateCollegeAnalytics(e)} className={path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/StudentWiseAnalytics" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/CollegeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/StudentWiseAnalytics" ? "https://ccicons.s3.amazonaws.com/analytics/icons8-analytics-90+2.svg" : "https://ccicons.s3.amazonaws.com/analytics/icons8-analytics-90.svg"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Analytics</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateResumeAnalytics(e)} className={path === "/ResumeAnalytics" || path === "/BatchWiseAnalytics" || path === "/StudentProcess" || path === "/DiagnosticInterview" || path === "/StudentWiseAnalytics" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/ResumeAnalytics" || path === "/ResumesDigitalAnalytics" ? "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDLogo.png" : "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDLogo.png"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Resume Analytics</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            {/* <li class="has-subnav">
              <div
                style={{ display: "flex", flexDirection: "column", width: "100%" }}
                onClick={(e) => handleNavigatePlacementOps(e)}
                className={path === "/Batches" || path === "/PostingOpportunity" || path === "/JobsPostingPage" || path === "/AllApplicants" ? "sidenav-image-a-dashboard" : ""}
              >
                <ListItemButton style={{ padding: "0px" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <WorkOutlineIcon sx={{ margin: "0px", padding: "0px", fontSize: "18px" }} />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px" }}>
                    <span class="nav-text">Placement Ops</span>
                  </div>
                </ListItemButton>
              </div>
            </li> */}
            <li class="has-subnav">
              {/* <a href={"/CollegeAnalytics"}> */}
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => handleNavigateOfflineSession(e)} className={path === "/OfflineSession" || path === "/StudentSummary" ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  {/* <div style={{ padding: "15px", margin:16, marginRight: 10, paddingRight:'29px', marginTop:0, marginBottom:0, borderRight: '2px solid rgba(0, 0, 0, 1)'}} > */}
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <img
                      alt="logo"
                      src={path === "/OfflineSession" || path === "/StudentSummary" ? "https://ccicons.s3.amazonaws.com/offline/OfflineCollAdminIcon.svg" : "https://ccicons.s3.amazonaws.com/offline/OfflineCollAdminBlack.svg"}
                      style={{
                        // margin: "17px 6px 75px 12px",
                        // marginLeft: "6%",
                        width: "18px",
                        height: "18px",
                        // float: "left",
                        // cursor: "pointer",
                      }}
                    />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Offline Sessions</span>
                  </div>
                </ListItemButton>
              </div>
              {/* </a> */}
            </li>
            {/* Profile  */}
            {/* <li class="has-subnav">
              <div style={{ display: "flex", width: "100%" }} onClick={(e) => navigate("/Profile")}
                className={(path === "/Profile") ? "sidenav-image-a-dashboard" : ""}>
                <ListItemButton style={{ padding: "0px" }}>
                  <div
                    style={{
                      padding: "15px",
                      margin: 16,
                      marginRight: 10,
                      paddingRight: "29px",
                      marginTop: 0,
                      marginBottom: 0,
                      borderRight: "none",
                    }}
                  >
                    <Person />
                  </div>
                  <div style={{ padding: "10px 0px 10px 0px", width: "80%" }}>
                    <span class="nav-text">Profile</span>
                  </div>
                </ListItemButton>
              </div>
            </li> */}
          </ul>
          <ul class="logout" onClick={(e) => handleLogout(e)}>
            <li class="has-subnav">
              <a className="sidenav-image-a">
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ padding: "18px 0px 18px 30px", width: "100px" }}>
                    <i class="fa fa-power-off"></i>
                  </div>
                  <div style={{ padding: "14px 0px 9px 0px", width: "80%" }}>
                    <span class="nav-text">Logout</span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideNavigationBar;
