import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Color } from "../../../GlobalStyles";
import CCService from "../../../services/httpServiceWithoutSuperAdmin";
import Loader from "../../reusables/Loader/Loader";
import SideNavigationBar from "../../reusables/SideNavigationBar/SideNavigationBar";

function NewCollegeAnalytics() {
  const service = new CCService();
  const location = useLocation();

  const navigate = useNavigate();

  const [selectedColleges, setSelectedCollege] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [allBatches, setAllBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const college_id = location.state?.college_id;
  const college_name = location.state?.college_name;
  const fromResumesTemplatePath = location.state?.fromResumesTemplatePath;
  const [resumesDigitalAnalyticsData, setResumesDigitalAnalyticsData] = useState([]);

  const handleBatchSelect = (e, college) => {
    setSelectedBatch(e.target.value);
  };
  const handleStudentAnalytics = (program_id, program_name, activated_students, non_activated_students, college) => {
    console.log("id", selectedColleges, selectedBatch);
    navigate("/StudentProcess", { state: { college_id: college_id, college_name: college_name, program_id: program_id, program_name: program_name, activated_students: activated_students, non_activated_students: non_activated_students, batch: college } });
  };

  const handleBatchAnalytics = (program_id, program_name, activated_students, non_activated_students) => {
    navigate("/BatchWiseAnalytics", { state: { college_id: college_id, college_name: college_name, program_id: program_id, program_name: program_name, activated_students: activated_students, non_activated_students: non_activated_students } });
  };

  const batchResumesDigitalAnalyticsDataGetter = async (process_ids) => {
    try {
      const res = await service.get(`/analytics/user/alResumeProcess?process_ids=[${process_ids.join(",")}]`);
      console.log("analytics data response:", res.batchAnalysisData);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message);
    }
  };

  const batchResumesDigitalProcessGetter = async (batch_id) => {
    try {
      const res = await service.get(`/user/processIdsForDashboard?ug_id=${batch_id}`);
      console.log("analytics data response:", res.prepared_data);
      return res.data;
    } catch (err) {
      console.log(err);
      window.alert(err.response.data.message);
    }
  };

  useEffect(() => {
    const getBatchData = async () => {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await service.get("/clgAdmin/Program", { headers });
      console.log("allBatches", res.data);
      let temp_batch = res.data.map((item) => {
        return {
          ...item,
          is_loading: false,
        };
      });
      console.log("temp_batch", temp_batch);

      setAllBatches(temp_batch);
    };

    getBatchData();
    setLoading(false);
  }, [selectedColleges]);

  const handleResumeAnalyticsClick = async (index, batch_id, batch_name, is_loading, activated_students, non_activated_students) => {
    try {
      setAllBatches((prevCardInfo) => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
      let batchResumesDigitalGetter = await batchResumesDigitalProcessGetter(batch_id);
      const processIds = batchResumesDigitalGetter.map((item) => item.process_id);
      console.log("processIds", processIds);
      if (processIds.length > 0) {
        const resumeDigitalResult = await batchResumesDigitalAnalyticsDataGetter(processIds);
        setResumesDigitalAnalyticsData(resumeDigitalResult);
        if (resumeDigitalResult) {
          navigate("/ResumesDigitalAnalytics", { state: { resumesDigitalAnalyticsData: resumeDigitalResult, fromResumesTemplatePath: true, college_name: college_name, batch_name: batch_name, process_id: processIds } });
        } else {
          window.alert("No students present");
        }
      } else {
        window.alert("Resume Analytics Dashboard Not available");
      }
      setAllBatches((prevCardInfo) => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
    } catch (err) {
      setAllBatches((prevCardInfo) => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
      window.alert(err.response.data.message);
    }
  };

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };

  return (
    <div>
      <SideNavigationBar college_id={college_id} college_name={college_name} />
      <Box sx={{ padding: "5rem" }}>
        <div className="topHeadings" style={{ display: "flex", marginLeft: "3rem", marginBottom: "3rem" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem", marginTop: ".5rem" }} onClick={() => goBack()} />
          <p style={{ color: Color.neutralMidGrey }}>Batches</p>
        </div>
        {loading === false ? (
          <>
            <Grid container columnGap={4} rowGap={6} sx={{ display: "flex", justifyContent: "left", marginLeft: "5rem" }}>
              {allBatches.map((college, index) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    style={{
                      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                      transition: "box-shadow 0.3s",
                      borderRadius: "1rem",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = "0px 5px 20px rgba(0, 0, 0, 0.5)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = "0px 5px 15px rgba(0, 0, 0, 0.35)";
                    }}
                    onClick={() => setSelectedCollege(college)}
                  >
                    <CardContent>
                      <Typography variant="h5" sx={{ fontSize: "20px" }}>
                        {college?.program_name}
                      </Typography>
                      <div className="container" style={{ margin: ".2rem 0" }}>
                        <Typography variant="body2" color={Color.rdcolor} class="providingSpaceForSubDetails" style={{ marginLeft: "0rem" }}>
                          Total Students:
                        </Typography>
                        <Typography variant="body2" class="providingSpaceForSubDetails">
                          {college?.activated_students + college?.non_activated_students}
                        </Typography>
                      </div>
                      {fromResumesTemplatePath ? (
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "10px", marginTop: ".5rem" }}>
                          <button
                            style={{
                              padding: "12px",
                              border: "none",
                              background: Color.rdcolor,
                              borderRadius: "10px",
                              color: "#fff",
                              width: "100%",
                              fontSize: "12px",
                            }}
                            onClick={() => handleResumeAnalyticsClick(index, college?.program_id, college?.program_name, college?.is_loading, college?.activated_students, college?.non_activated_students)}
                          >
                            {college?.is_loading ? <CircularProgress sx={{ color: "#fff" }} size={15} /> : "Resume Analytics"}
                          </button>
                        </div>
                      ) : (
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "10px", marginTop: ".5rem" }}>
                          <button
                            style={{
                              padding: "12px",
                              border: "none",
                              background: "#561d5e",
                              borderRadius: "10px",
                              color: "#fff",
                              width: "100%",
                              fontSize: "12px",
                            }}
                            onClick={() => handleBatchAnalytics(college?.program_id, college?.program_name, college?.activated_students, college?.non_activated_students)}
                          >
                            Batch Analytics
                          </button>
                          <button
                            style={{
                              padding: "12px",
                              border: "none",
                              background: "#561d5e",
                              borderRadius: "10px",
                              color: "#fff",
                              width: "100%",
                              fontSize: "12px",
                            }}
                            onClick={() => handleStudentAnalytics(college?.program_id, college?.program_name, college?.activated_students, college?.non_activated_students, college)}
                          >
                            Student Analytics
                          </button>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto", marginLeft: "45%", marginTop: "10%" }}>
              <Loader show={loading} />
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default NewCollegeAnalytics;
